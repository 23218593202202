import React, {Component} from 'react'
import {ProjectInterface} from "../../interfaces"
import {Link} from "react-router-dom"
import moment from "moment"
import {ReactComponent as Arrow} from '../../res/svg/arrow.svg'

class ProjectBox extends Component<ProjectInterface> {
	render() {
		const date: string = moment(this.props.lastUpdate ?? this.props.date).format("MMMM YYYY")
		
		return (
			<div className="project-box flex flex-center-vert">
				<span className="img-viewer flex flex-center">
					<span className="img-container">
						<Link to={`/projects/${this.props.code}`}>
							<img className={`${this.props.thumbnailImage.full ? "full" : "clip"}`} src={`/img/projects/${this.props.code}/${this.props.thumbnailImage.url}`} alt={`Thumbnail project ${this.props.name}`}/>
						</Link>
					</span>
				</span>
				<span className="info-container">
					<h2 className="title title-big notranslate"><Link to={`/projects/${this.props.code}`}>{this.props.name}</Link></h2>
					<div className="list-tag">
						{this.props.tags.map((tag) => {
							return (<span key={tag._id} className="tag-item">{tag.name}</span>)
						})}
					</div>
					<p className="description">
						{this.props.teaser}
					</p>
					<div className="date">{date}</div>
					<div className="link-container flex flex-end"><Link className="strong" to={`/projects/${this.props.code}`}>
						<span className="margin-right-1">Discover</span>
						<Arrow/>
					</Link></div>
				</span>
			</div>
		)
	}
}

export default ProjectBox