import React, {Component} from 'react'
import {ReactComponent as Arrow} from '../../res/svg/arrow.svg'

type anchorProps = {
	children: React.ReactNode,
	anchorId: string,
	direction: "up" | "down"
}

class Anchor extends Component<anchorProps> {
	
	scrollTo = (evt: React.MouseEvent) => {
		// Prevent instant default scroll
		evt.preventDefault()
		
		const anchor = document.getElementById(this.props.anchorId);
		
		// If anchor element exists
		if(anchor) {
			anchor.scrollIntoView({
				behavior: 'smooth'
			});
		}
	}
	
	render() {
		return (
			<a className={`anchor strong anchor-${this.props.direction}`} href={`#${this.props.anchorId}`} onClick={this.scrollTo}>
				{this.props.children}
				<Arrow/>
			</a>
		)
	}
}

export default Anchor