import React, {Component} from 'react'
import martinBoue from '../../res/img/martin-boue.jpg'
import Anchor from "../general/Anchor"
import {Link} from "react-router-dom"
import AnimatedText from "../general/AnimatedText"
const resume = require('../../res/CV-Martin-BOUE-Software-Engineer-FR.pdf')

class AboutHeader extends Component {
	render() {
		return (
			<section className="about-header flex flex-center-vert">
				<div className="img-container">
					<img src={martinBoue} alt="Martin Boué"/>
				</div>
				<div className="title-container flex flex-col flex-start">
					<h1 className="title-big">Martin Boué</h1>
					<p className="subtitle notranslate">French fullstack developer<br/>& engineer passionate<br/>about <AnimatedText words={['programming', 'IT', 'engineering', 'design', 'creating']}/></p>
					<Link className="btn" target="_blank" to={resume}>Resume</Link>
				</div>
				<Anchor direction="down" anchorId="skills">Continue</Anchor>
			</section>
		)
	}
}

export default AboutHeader