import React, {Component} from 'react'
import ButtonGroup from "../form/ButtonGroup"
import experiences from './experiences.json'
import education from './education.json'
import BigList from "./BigList"

type experiencesState = {
	panels: {label: string, isActive: boolean}[],
	
}

class Experiences extends Component<{}, experiencesState> {
	state: experiencesState = {
		panels: [{label: "Experiences", isActive: true}, {label: "Education", isActive: false}]
	}
	
	switchPanel = (evt: React.MouseEvent) => {
		this.setState({
			panels:
				this.state.panels.map((panel) => {
					return {...panel, isActive: evt.currentTarget.textContent === panel.label}
				})
		})
	}
	
	render() {
		let currentPanel = this.state.panels.find((panel) => {
			return panel.isActive
		})
		
		if(!currentPanel) {
			currentPanel = this.state.panels[0]
		}
		
		return (
			<section className="experiences">
				<div className="flex flex-center-hori">
					<ButtonGroup onClick={this.switchPanel} buttons={this.state.panels}/>
				</div>
				<div className="experiences-body">
					{currentPanel.label === 'Experiences' && (
						<div className="panel">
					      <h2 className="title-big">Experiences</h2>
							<BigList items={experiences}/>
						</div>
					)}
					{currentPanel.label === 'Education' && (
						<div className="panel">
							<h2 className="title-big">Education</h2>
							<BigList items={education}/>
						</div>
					)}
				</div>
			</section>
		)
	}
}

export default Experiences