import React, { Component } from 'react'
import { BrowserRouter } from 'react-router-dom';
import { Route, Switch } from 'react-router';
import ScrollRestoration from './general/ScrollRestoration'

// Components
import App from "./App"
import Error404Page from "./general/Error404Page"
import HomePage from "./home/HomePage"
import AboutPage from "./about/AboutPage"
import ListProjectPage from "./project/ListProjectPage"
import ProjectPage from "./project/ProjectPage"
import ContactPage from "./contact/ContactPage"

class Router extends Component {
	render() {
		return(
			<BrowserRouter>
				<ScrollRestoration>
					<App>
						<Switch>
							<Route exact path="/" component={HomePage} />
							<Route exact path="/about" component={AboutPage} />
							<Route exact path="/projects" component={ListProjectPage} />
							<Route exact path="/projects/:code" component={ProjectPage} />
							<Route exact path="/contact" component={ContactPage} />
							<Route component={Error404Page} />
						</Switch>
					</App>
				</ScrollRestoration>
			</BrowserRouter>
		)
	}
}

export default Router
