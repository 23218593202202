import React, {Component} from 'react'
import {Link} from "react-router-dom"
import {ReactComponent as ArrowOut} from "../../res/svg/arrow-out.svg"

type pageFooterProps = {
	title: string,
	subtitle: string,
	linkUrl: string,
	linkText: string,
	className?: string,
	isLinkOut?: boolean
}

class PageFooter extends Component<pageFooterProps> {
	render() {
		return (
			<section className={`page-footer flex flex-col flex-center-hori center ${this.props.className}`}>
				<h3 className="title-big">{this.props.title}</h3>
				<p className="subtitle">{this.props.subtitle}</p>
				{this.props.isLinkOut ? (
					<a className="btn" target="_blank" rel="noopener noreferrer" href={this.props.linkUrl}>
						<span className="margin-right-1">{this.props.linkText}</span>
						<ArrowOut/>
					</a>
				) : (
					<Link className="btn" to={this.props.linkUrl}>{this.props.linkText}</Link>
				)}
			</section>
		)
	}
}

export default PageFooter