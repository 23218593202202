import React, {Component} from 'react'
import {ReactComponent as Console} from '../../res/svg/console.svg'
import {ReactComponent as Agile} from '../../res/svg/agile.svg'
import {ReactComponent as Design} from '../../res/svg/design.svg'

class Domains extends Component {
	render() {
		return (
			<section className="domains flex flex-wrap flex-center-hori">
				<div className="domain flex flex-col flex-center-hori">
					<div className="icon-container"><Console/></div>
					<div className="domain-body">
						<h2 className="title">Engineer</h2>
						<p className="subtitle">Creating, improving, or maintaining software is my daily job. I know how to work in a team on various technologies, environments and sectors of activity.</p>
					</div>
				</div>
				
				<div className="domain flex flex-col flex-center-hori">
					<div className="icon-container"><Agile/></div>
					<div className="domain-body">
						<h2 className="title">Agile</h2>
						<p className="subtitle">In agile method, respect objectives, manage a schedule or a team, I'm used to it and I am trained for it. I advocate sharing information and good mood.</p>
					</div>
				</div>
				
				<div className="domain flex flex-col flex-center-hori">
					<div className="icon-container"><Design/></div>
					<div className="domain-body">
						<h2 className="title">Designer</h2>
						<p className="subtitle">I am also able to design ergonomic, user-friendly and beautiful applications. I pay attention to intuitiveness and user experience.</p>
					</div>
				</div>
			</section>
		)
	}
}

export default  Domains