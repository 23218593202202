import React, {Component} from 'react'
import {Helmet} from "react-helmet"
import {RouteComponentProps, withRouter} from "react-router"

type pageProps = {
	className: string,
	description: string,
	title?: string,
	noIndex?: boolean
}

class Page extends Component<pageProps & RouteComponentProps> {
	
	render() {
		return (
			<main className={`page ${this.props.className}`}>
				<Helmet>
					{this.props.noIndex && (
						<meta name="robots" content="noindex"/>
					)}
					{this.props.noIndex ? (
						<link rel="canonical" href="https://martinboue.fr"/>
					) : (
						<link rel="canonical" href={`https://martinboue.fr${window.location.pathname}`}/>
					)}
					<title>{`${this.props.title ? `${this.props.title} – ` : ''}Martin Boué – Software engineer & Designer`}</title>
					<meta name="description" content={this.props.description}/>
				</Helmet>
				{this.props.children}
			</main>
		)
	}
}

export default withRouter(Page)