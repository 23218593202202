import React, {Component} from 'react'

type tagProps = {
	value: string,
	isActive: boolean,
	onClick: (value: string) => void
}

class Tag extends Component<tagProps> {
	
	onClick = () => {
		this.props.onClick(this.props.value)
	}
	
	render() {
		return (
			<span className={`tag ${this.props.isActive ? 'active' : 'inactive'}`} onClick={this.onClick}>{this.props.children}</span>
		)
	}
}

export default Tag