import React from 'react';
import '../sass/App.scss';

// Components
import Navbar from './general/Navbar'
import Footer from "./general/Footer"

const App: React.FC = (props) => {
	return (
		<div className="App">
			<Navbar/>
				{props.children}
			<Footer/>
		</div>
	);
}

export default App;
