import React, {Component} from 'react'

type animatedTextProps = {
	words: string[]
}

type animatedTextState = {
	nbLetters: number,
	index: number,
	timer?: NodeJS.Timeout
}

class AnimatedText extends Component<animatedTextProps, animatedTextState> {
	state: animatedTextState = {
		nbLetters: 0,
		index: 0
	}
	
	componentWillUnmount() {
		// Stop timer before unmount
		if(this.state.timer) {
			clearInterval(this.state.timer)
		}
	}
	
	componentDidMount(): void {
		// Display first word
		this.setTimer(this.fadeIn, 1000)
	}
	
	fadeIn = () => {
		// Display next letter
		const letters = this.state.nbLetters + 1;
		this.setState({
			nbLetters: letters
		})

		// Get word length
		// we use Array.from otherwise emoji will count for 2 character with stringWithEmoji.length
		const wordLength = Array.from(this.props.words[this.state.index]).length

		// If word isn't complete
		if (letters < wordLength) {
			// Display next letter later
			this.setTimer(this.fadeIn, 100 + Math.random() * 100)
		} else {
			// Else remove word later
			this.setTimer(this.fadeOut, 2000)
		}
	}
	
	fadeOut = () => {
		// Remove last letter
		const letters = this.state.nbLetters - 1;
		this.setState({
			nbLetters: letters
		})
		
		// If there is still letters
		if(letters > 0) {
			// Remove next last letter later
			this.setTimer(this.fadeOut, 50 + Math.random() * 50)
		} else {
			// Else display next word later
			this.setTimer(this.nextWord, 100)
		}
	}
	
	nextWord = () => {
		this.setState({
			index: (this.state.index + 1) % this.props.words.length
		})
		this.fadeIn()
	}
	
	setTimer = (f: () => void, ms: number) => {
		this.setState({
			timer: setTimeout(f, ms)
		})
	}
	
	render() {
		const letters = Array.from(this.props.words[this.state.index])
		return (
			<span className="animated-text">
				{letters.map((letter, index) => {
					return index < this.state.nbLetters ? (<span key={index} className="strong">{letter}</span>) : '';
				})}
				<span className={`text-pointer ${this.state.nbLetters === letters.length || this.state.nbLetters === 0 ? "waiting" : "writing"}`}>_</span>
			</span>
		)
	}
}

export default AnimatedText