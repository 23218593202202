import React, {Component} from 'react'

type inputProps = {
	type: "text" | "email" | "tel" | "number" | "textarea",
	label: string,
	onChange: (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
	value: string | number,
	id: string,
	className?: string
	cols?: number
	rows?: number
	required?: boolean
	minLength?: number
	maxLength?: number
}

class Input extends Component<inputProps> {
	render() {
		return (
			<div className={`input input-${this.props.type} ${this.props.className}`}>
				{this.props.type === "textarea" ? (
					<textarea 
						id={this.props.id} 
						name={this.props.id} 
						value={this.props.value} 
						onChange={this.props.onChange}
						cols={this.props.cols ? this.props.cols : 30} 
						rows={this.props.rows ? this.props.rows : 10}
						className={this.props.value !== '' ? 'filled' : 'empty'} 
						required={this.props.required}
						minLength={this.props.minLength}
						maxLength={this.props.maxLength}/>
				) : (
					<input 
						id={this.props.id} 
						name={this.props.id} 
						type={this.props.type} 
						value={this.props.value} 
						onChange={this.props.onChange}
						className={this.props.value !== '' ? 'filled' : 'empty'} 
						required={this.props.required}
						minLength={this.props.minLength}
						maxLength={this.props.maxLength}/>
				)}
				<label htmlFor={this.props.id}>{this.props.label}</label>
			</div>
		)
	}
}

export default Input