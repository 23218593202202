import React, {Component} from 'react'

type bigListProps = {
	items: {title: string, description: string, imageUrl: string, imageAlt: string, date: string, location: string, linkUrl?: string}[]
}

class BigList extends Component<bigListProps> {
	render() {
		return (
			<ul className="big-list">
				{this.props.items.map((item) => {
					return (
						<li key={item.title} className="big-list-item flex flex-separate">
							<div className="item-image flex flex-center">
								{item.linkUrl ? (
									<a className="flex flex-center" target="_blank" rel="noopener noreferrer" href={item.linkUrl}>
										<img src={`/img/logos/${item.imageUrl}`} alt={item.imageAlt}/>
									</a>
								) : (
									<img src={`/img/logos/${item.imageUrl}`} alt={item.imageAlt}/>
								)}
							</div>
							<div className="item-content">
								<div className="item-header">
									<div className="item-title flex flex-separate flex-bottom">
										<h3 className="title">{item.title}</h3>
										<div className="date">{item.date}</div>
									</div>
									{item.linkUrl ? (
										<a className="subtitle strong" target="_blank" rel="noopener noreferrer" href={item.linkUrl}>{item.location}</a>
									) : (
										<p className="subtitle">{item.location}</p>
									)}
								</div>
								<div className="item-body">
									<p className="subtitle">{item.description}</p>
								</div>
							</div>
							
						</li>
					)
				})}
			</ul>
		)
	}
}

export default BigList