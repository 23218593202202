import React, {Component} from 'react'
import {Link} from "react-router-dom"
import {ReactComponent as GitHubIcon} from '../../res/svg/github.svg'
import {ReactComponent as ItchIoIcon} from '../../res/svg/itchio.svg'
import {ReactComponent as DribbbleIcon} from '../../res/svg/dribbble.svg'
import {ReactComponent as TwitterIcon} from '../../res/svg/twitter.svg'
import {ReactComponent as LinkedInIcon} from '../../res/svg/linkedin.svg'

class Footer extends Component {
	render() {
		return (
			<footer className="footer flex flex-center-hori">
				<div className="footer-body flex flex-center-hori">
					<div className="footer-col-cont flex">
						<div className="footer-col">
							<h2>Contact</h2>
							<ul>
								<li>contact@martinboue.fr</li>
								<li>Rennes (35), France</li>
							</ul>
						</div>
						
						<div className="footer-col">
							<h3>Navigate</h3>
							<nav>
								<ul className='list-link'>
									<li className='link-item'><Link to="/">Home</Link></li>
									<li className='link-item'><Link to="/about">About</Link></li>
									<li className='link-item'><Link to="/projects">Projects</Link></li>
									<li className='link-item'><Link to="/contact">Contact</Link></li>
								</ul>
							</nav>
						</div>
					</div>
					
					<div className="footer-col-cont flex">
						<div className="footer-col">
							<h3>Social</h3>
							<ul>
								<li><a href="https://www.linkedin.com/in/martin-boue/" target="_blank" rel="noopener noreferrer"><LinkedInIcon/>LinkedIn</a></li>
								<li><a href="https://github.com/martinboue" target="_blank" rel="noopener noreferrer"><GitHubIcon/>GitHub</a></li>
								<li><a href="https://martinboue.itch.io/" target="_blank" rel="noopener noreferrer"><ItchIoIcon/>itch.io</a></li>
								<li><a href="https://twitter.com/martin_boue" target="_blank" rel="noopener noreferrer"><TwitterIcon/>Twitter</a></li>
								<li><a href="https://dribbble.com/martinboue" target="_blank" rel="noopener noreferrer"><DribbbleIcon/>Dribbble</a></li>
							</ul>
						</div>
						
					</div>
				</div>
				
			</footer>
		)
	}
}

export default Footer