import React, {Component} from 'react'
import Button from "./Button"

type buttonGroupProps = {
	buttons: {label: string, isActive: boolean}[],
	onClick: (evt: React.MouseEvent) => void
}

type buttonGroupState = {
	chosen: string
}

class ButtonGroup extends Component<buttonGroupProps, buttonGroupState> {
	
	handleClick = (evt: React.MouseEvent) => {
		this.props.onClick(evt)
	}
	
	render() {
		return (
			<div className="btn-group flex flex-wrap flex-center">
				{this.props.buttons.map((button) => {
					return (
						<Button key={button.label} className={button.isActive ? "active" : "inactive"} onClick={this.handleClick}>{button.label}</Button>
					)
				})}
			</div>
		)
	}
}

export default ButtonGroup