import React, {Component} from 'react'
import TopicSkill from "./TopicSkill"
import {TopicInterface} from "../../interfaces"

class ListTopicSkill extends Component<{topics: TopicInterface[]}> {
	render() {
		return (
			<section id="skills" className="list-skill">
				<h2 className="title title-big">Skills</h2>
				<div className="list-topic">
					{this.props.topics.map((topic) => {
						return (<TopicSkill key={topic._id} {...topic}/>)
					})}
				</div>
			</section>
		)
	}
}

export default ListTopicSkill