import {Component} from "react"
import {RouteComponentProps, withRouter} from "react-router"

class ScrollRestoration extends Component<RouteComponentProps> {
	
	componentDidUpdate(prevProps: RouteComponentProps) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			window.scrollTo(0, 0);
		}
	}
	
	render() {
		return this.props.children
	}
}

export default withRouter(ScrollRestoration)