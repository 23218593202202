import React, {Component} from 'react'
import {TopicInterface} from "../../interfaces"
import {ReactComponent as Arrow} from '../../res/svg/arrow-up.svg'

type topicSkillState = {
	isOpen: boolean
}

class TopicSkill extends Component<TopicInterface, topicSkillState> {
	state: topicSkillState = {
		isOpen: false
	}
	
	toggleOpen = () => {
		this.setState({
			isOpen: !this.state.isOpen
		})
	}
	
	render() {
		return (
			<div className="topic">
				<div className="topic-header flex flex-separate flex-center-vert" onClick={this.toggleOpen}>
					<div className="topic-header-cont flex flex-center-vert">
						<div className="icon-container flex flex-center">
							<img src={`/svg/${this.props.icon}`} alt=""/>
						</div>
						<div className="text-cont">
							<h3 className="title">{this.props.name}</h3>
							{!this.state.isOpen && (
								<p className="subtitle">{this.props.description}</p>
							)}
						</div>
					</div>
					<div className={`btn-container flex flex-end flex-center-vert ${this.state.isOpen ? "up" : "down"}`}>
						<Arrow/>
					</div>
				</div>
				{this.state.isOpen && (
					<div className="topic-body">
						<table>
							<tbody>
								{this.props.skills.map((skill) => {
									return (
										<tr key={skill._id}>
											<td>{skill.name}</td>
											<td>{skill.description}</td>
										</tr>
									)
								})}
							</tbody>
						</table>
					</div>
					
				)}
			</div>
		)
	}
}

export default TopicSkill