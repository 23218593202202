import React, {Component} from 'react'
import Page from "../general/Page"
import {Redirect, RouteComponentProps, withRouter} from "react-router"
import Modal from "../general/Modal"
import PageFooter from "../general/PageFooter"
import {Link} from "react-router-dom"
import projects from "./projects.json"
import {ProjectInterface} from "../../interfaces"
import _ from "lodash"
import Loader from "../general/Loader"
import moment from "moment"
import {ReactComponent as Arrow} from '../../res/svg/arrow.svg'
import {ReactComponent as ArrowOut} from "../../res/svg/arrow-out.svg"

type ProjectPageState = {
	project: ProjectInterface
}

class ProjectPage extends Component<RouteComponentProps<{code: string}>, ProjectPageState> {
	state: ProjectPageState = {
		project: null
	}
	
	componentDidMount(): void {
		const list : ProjectInterface[] = projects
		
		this.setState({
			project: _.find(list, ["code", this.props.match.params.code])
		})
	}
	
	render() {
		const project = this.state.project
		const isLoading = project === null
		const projectExists = project !== undefined
		
		return (
			<Page className={`project ${isLoading ? 'flex flex-center' : ''}`}
			      title={!isLoading && projectExists ? project.name : 'Project ' + this.props.match.params.code}
			      description={!isLoading && projectExists ? (project.description) : 'Project ' + this.props.match.params.code}>
				{isLoading ? (
					<section className="center">
						<Loader/>
					</section>
				) : !projectExists ? (
					<Redirect to="/error404"/>
				) : (
					<div>
						<section className="project-header">
							<div className="project-header-cont flex flex-separate">
								<div className="project-header-left">
									<div><Link className="link strong" to="/projects"><Arrow/><span className="margin-left-1">Back to projects</span></Link></div>
									<h1 className="title-big notranslate">{project.name}</h1>
									<p className="subtitle">
										{project.description}
									</p>
									<p className="subtitle date">
										Published {moment(project.date).format("MMMM D, YYYY")}
										{project.lastUpdate != null && ` — updated ${moment(project.lastUpdate).format("MMMM D, YYYY")}`}
									</p>
									{project.link != null && (
										<a className="btn" href={project.link} target="_blank" rel="noopener noreferrer">
											<span className="margin-right-1">See website</span>
											<ArrowOut/>
										</a>
									)}
								</div>
								<div className={`project-header-right flex flex-end ${project.backgroundImage.full ? "stick" : "away"}`}>
									<span className="img-cont">
										<img src={`/img/projects/${project.code}/${project.backgroundImage.url}`} alt={`Background ${project.backgroundImage.description}`}/>
									</span>
								</div>
							</div>
						</section>
						<section className="project-body">
							{project.images.length > 0 && (
								<div className="flex flex-col flex-center-hori">
									{project.images.map((image) => {
										return (
											<Modal key={image.url} className="project-image">
												<img className={`${image.full ? "full" : "clip"}`} src={`/img/projects/${project.code}/${image.url}`} alt={image.description}/>
											</Modal>
										)
									})}
								</div>
							)}
						</section>
						
						<PageFooter title="So, what do you think?" linkText="Leave a comment" linkUrl="/contact" subtitle="Tell me what do you like or dislike in this project. If you have any comments, suggestions or anything, please contact me. I will be glad to read your feedbacks. Thank you."/>
					
					</div>
				)}
			</Page>
		)
	}
}

export default withRouter(ProjectPage)