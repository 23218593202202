import React, {Component} from 'react'

class Loader extends Component {
	render() {
		return (
			<svg className="loader" viewBox="25 25 50 50">
				<circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="3" strokeMiterlimit="10"/>
			</svg>
		)
	}
}

export default Loader