import React, {Component} from 'react'

type toggleProps = {
	id: string,
	label: string,
	onChange: () => void,
	value: boolean
}

class Toggle extends Component<toggleProps> {
	render() {
		return (
			<div className="toggle">
				<input type="checkbox" id={this.props.id} onChange={this.props.onChange} checked={this.props.value}/>
				<label className="flex flex-center-vert" htmlFor={this.props.id}>{this.props.label}</label>
			</div>
		)
	}
}

export default Toggle