import React, {Component} from 'react'
import Page from "../general/Page"
import Tag from "../general/Tag"
import {ProjectInterface, TagInterface} from "../../interfaces"
import ProjectBox from "./ProjectBox"
import PageFooter from "../general/PageFooter"
import _ from "lodash"
import projects from "./projects.json"

type listProjectPageProps = {
	projects: ProjectInterface[]
}

type listProjectPageState = {
	tags: TagInterface[],
	selectedTagId: string
}

class ListProjectPage extends Component<listProjectPageProps, listProjectPageState> {
	static defaultProps: listProjectPageProps = {
		projects: projects as ProjectInterface[]
	}
	
	state: listProjectPageState = {
		tags: [],
		selectedTagId: null
	}
	
	sortTags = (): TagInterface[] => {
		let tags: TagInterface[] = []
		const projects : ProjectInterface[] = this.props.projects
		
		if(projects != null) {
			// For each project
			projects.forEach((project) => {
				// For each tag
				project.tags.forEach((tag) => {
					if (tag.importance > 0) {
						// If not already in
						if (_.find(tags, ["_id", tag._id]) === undefined) {
							tags.push(tag)
						}
					}
				})
			})
		}
		
		// Sort tags by importance
		tags = _.orderBy(tags,['importance'], ['desc'])
		
		return tags
	}
	
	sortProjects = (): ProjectInterface[] => {
		let projects: ProjectInterface[] = this.props.projects
		
		// If there is projects
		if (projects != null) {
			
			// If there is a selected id (not All)
			if (this.state.selectedTagId != null) {
				projects = _.filter(projects, (project) => {
					for (let t = 0; t < project.tags.length; t++) {
						// If project has the selected tag
						if (project.tags[t]._id === this.state.selectedTagId) {
							return true
						}
					}
					return false
				})
			}
			
		} else {
			projects = []
		}
		
		return projects
	}
	
	chooseTag = (value: string) => {
		this.setState({
			selectedTagId: value
		})
	}
	
	render() {
		const tags = this.sortTags()
		const projects = this.sortProjects()
		
		return (
			<Page className={`list-project`} title="Projects Martin Boué - Web app, mobile app and more" description='Discovers all my projects and technologies: web application, mobile application, API in Java, Node.JS, PHP, Javascript with Angular, React, Symfony, Laravel, Spring, Git...'>
				<div>
					<section className="list-project-header">
						<h1 className="title-big">Projects</h1>
						<div className="list-tag">
							<Tag value={null} isActive={this.state.selectedTagId === null} onClick={this.chooseTag}>All</Tag>
							{tags.map((tag) => {
								return (<Tag key={tag._id} value={tag._id} isActive={tag._id === this.state.selectedTagId} onClick={this.chooseTag}>{tag.name}</Tag>)
							})}
						</div>
					</section>
					
					<section className="list-project-body">
						{projects.map((project) => {
							return (<ProjectBox key={project._id} {...project}/>)
						})}
					</section>
					<PageFooter title="If you want more..." linkText="GitHub" linkUrl="https://github.com/martinboue" isLinkOut={true} subtitle="The source code of some of the projects you have seen is publicly accessible on GitHub. Also, I might be working on a new open source project right now, feel free to check my repositories and activity."/>
				</div>
			</Page>
		)
	}
}

export default ListProjectPage