import React, {Component} from 'react'

type formProps = {
	onSubmit: () => void,
	action?: string
}

class Form extends Component<formProps> {
	
	onSubmit = (evt: React.FormEvent) => {
		if (this.props.action == null) {
			evt.preventDefault()
		}
		this.props.onSubmit()
	}
	
	render() {
		return (
			<form action={this.props.action != null ? this.props.action : ""} target="_blank" onSubmit={this.onSubmit}>
				{this.props.children}
			</form>
		)
	}
}

export default  Form