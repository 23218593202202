import React, {Component} from 'react'
import Page from "../general/Page"
import ListTopicSkill from "./ListTopicSkill"
import topics from './topicsSkills.json'
import AboutHeader from "./AboutHeader"
import Experiences from "./Experiences"
import PageFooter from "../general/PageFooter"


class AboutPage extends Component {
	render() {
		return(
			<Page className="about" title="About Martin Boué – Skills, experiences and more" description="About Martin Boué, software engineer & designer in France, Rennes (35). Discover my experiences, skills and education as a developer (Java, Node.js, Javascript, php, SQL, ...)">
				<AboutHeader/>
				<ListTopicSkill topics={topics}/>
				<Experiences/>
				<PageFooter title="Any questions?" subtitle="Do not hesitate to contact me to ask me questions, to tell me about your projects or just to get to know each other." linkText="Contact me" linkUrl="/contact"/>
			</Page>
		)
	}
}

export default AboutPage