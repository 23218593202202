import React, {Component} from 'react'
import laptopClosed from '../../res/img/laptop-closed.png'
import {Link} from "react-router-dom"
import video from "../../res/video/video.mp4"
import {ReactComponent as Arrow} from '../../res/svg/arrow.svg'

class Technologies extends Component {
	
	render() {
		return (
			<section className="technologies section-dark flex">
				<video id="video" preload="auto" poster={laptopClosed} autoPlay loop muted>
					<source src={video} type="video/mp4"/>
					<img src={laptopClosed} alt="Laptop closed"/>
					Your browser does not support the video tag.
				</video>
				<div className="app-ad-body center flex flex-col flex-center">
					<h2 className="title-big">Software, mobile app, game, ...</h2>
					<p className="subtitle">I like to work on many different project with new tools and platforms. I have created web or mobile applications with modern technologies, you can consult them now.</p>
					<Link className="link strong " to="/projects">
						<span className="margin-right-1">See all projects</span>
						<Arrow/>
					</Link>
				</div>
			</section>
		)
	}

}
export default Technologies