import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import Page from "./Page"
import {ReactComponent as Arrow} from "../../res/svg/arrow.svg";

class Error404Page extends Component {
	render() {
		return (
			<Page className="error404" title="Error 404" description="Error 404..." noIndex={true}>
				<div>
					<h1 className="title-big">Error 404</h1>
					<p className="subtitle">The page you are looking for doesn't exist.</p>
					<p className="subtitle info">If you encounter any difficulties on the site, please contact me <Link className="strong" to="/contact">here</Link>. Thank you.</p>
					<Link className="link strong" to="/">
						<span className="margin-right-1">Back to home</span>
						<Arrow/>
					</Link>
				</div>
			</Page>
		)
	}
}

export default Error404Page