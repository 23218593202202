import React, {Component} from 'react'
import Page from "../general/Page"
import Button from "../form/Button"
import Input from "../form/Input"
import Form from "../form/Form"
import {Link} from "react-router-dom"
import Toggle from "../form/Toggle"
import ReCAPTCHA from "react-google-recaptcha"
import axios from "axios"
import qs from "qs"
import {ReactComponent as Arrow} from '../../res/svg/arrow.svg'

type contactPageState = {
	fullName: string,
	email: string,
	message: string,
	captcha: string,
	sendCopy: boolean,
	sent: boolean,
	error: string
}

class ContactPage extends Component<{}, contactPageState> {
	state: contactPageState = {
		fullName: "",
		email: "",
		message: "",
		captcha: null,
		sendCopy: false,
		sent: false,
		error: null
	}
	
	onChange = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const key: string = evt.target.name;
		this.setState({
			[key]: evt.target.value
		} as Pick<contactPageState,"fullName" | "email" | "message">)
	}
	
	onSubmit = async () => {
		// Verify captcha is validated
		if (this.state.captcha != null) {
			
			const url = "/mail.php"
			
			// Send request to back
			axios({
				method: "post",
				url: url,
				data: qs.stringify({
					fullName: this.state.fullName,
					email: this.state.email,
					message: this.state.message,
					captcha: this.state.captcha,
					sendCopy: this.state.sendCopy
				}),
				headers: {'Content-Type': 'application/x-www-form-urlencoded' }
			}).then((res) => {
				this.setState({
					sent: true
				})
				// Scroll to top
				window.scrollTo(0, 0);
			}).catch((error) => {
				this.setState({
					error: error.response.data.message
				})
			})
			
		} else {
			this.setState({
				error: "Please check that you are not a robot."
			})
		}
	}
	
	changeCaptcha = (value: string) => {
		// Set to null when Captcha expires
		this.setState({
			captcha: value
		})
	}
	
	toggleSendCopy = () => {
		this.setState({
			sendCopy: !this.state.sendCopy
		})
	}
	
	render() {
		return(
			<Page className="contact" title="Contact Martin Boué – Tell me about your projects" description="Contact Martin Boué, software engineer & designer. Please contact me if you want to discuss about a project or anything else. I'm ready to build any kind of application (web, desktop or mobile).">
				{this.state.sent ? (
					<section className="contact-confirm">
						<h1 className="title-big">Thank you!</h1>
						<p className="subtitle">Your message has been sent, I will do my best to answer you as soon as possible.</p>
						<p className="info subtitle">If you have any difficulties with the form, please contact me directly at <strong>contact@martinboue.fr</strong></p>
						<Link className="link strong" to="/">
							<span className="margin-right-1">Back to home</span>
							<Arrow/>
						</Link>
					</section>
				) : (
					<section>
						<h1 className="title-big">Get in touch!</h1>
						<Form onSubmit={this.onSubmit}>
							<div className="flex flex-separate form-row">
								<Input id="fullName" type="text" label="Fullname" value={this.state.fullName} onChange={this.onChange} required/>
								<Input id="email" type="email" label="Email" value={this.state.email} onChange={this.onChange} required/>
							</div>
							<div className="form-row">
								<Input id="message" type="textarea" label="Message" value={this.state.message} onChange={this.onChange} required maxLength={10000}/>
							</div>
							<p className="form-row subtitle">Your details will be kept confidential.</p>
							{this.state.error != null && (
								<div className="form-row error">{this.state.error}</div>
							)}
							<div className="form-row captcha">
								<ReCAPTCHA sitekey="6LfprscUAAAAADQTkaDerEGXl5gtQtceQDuIB622" onChange={this.changeCaptcha}/>
							</div>
							<div className="form-row submit-container flex flex-separate flex-center-vert">
								<Toggle value={this.state.sendCopy} onChange={this.toggleSendCopy} id="contact-copy" label="Receive a copy by mail"/>
								<Button shouldSubmit={true}>Send</Button>
							</div>
						</Form>
					</section>
				)}
			</Page>
		)
	}
}

export default ContactPage