import React, {Component} from 'react'
import Page from "../general/Page"
import Domains from "./Domains"
import Technologies from "./Technologies"
import HomeHeader from "./HomeHeader"


class HomePage extends Component {
	render() {
		return(
			<Page className="home flex flex-col flex-center-vert" description="Martin Boué, french software engineer, developer and designer ready to create projects. Take a look at my experiences, skills, projects and contact me.">
				<HomeHeader/>
				<Domains/>
				<Technologies/>
			</Page>
		)
	}
}

export default HomePage