import React, {Component} from 'react'
import {ReactComponent as X} from '../../res/svg/x.svg'

type modalProps = {
	children: React.ReactNode,
	title?: string,
	className?: string,
}

type modalState = {
	isOpen: boolean
}

class Modal extends Component<modalProps, modalState> {
	state: modalState = {
		isOpen: false
	}
	
	toggleOpen= () => {
		// Prevent page scroll
		document.body.style.overflow = this.state.isOpen ? "auto" : "hidden"
		this.setState({
			isOpen: !this.state.isOpen
		})
	}
	
	render() {
		return (
			<span className={`flex ${this.props.className ? this.props.className : ''} modal-container ${this.props.title ? 'modal-with-header' : 'modal-without-header'}`} onClick={this.toggleOpen}>
				{this.props.children}
				{this.state.isOpen && (
					<div className={`modal flex flex-center-hori ${this.state.isOpen ? "open" : "close"}`}>
						<div className="modal-content flex flex-center ">
							{this.props.title && (
								<div className="modal-header flex flex-center-vert flex-separate">
									<span>{this.props.title}</span>
									<button onClick={this.toggleOpen}>X</button>
								</div>
							)}
							<div className="icon-container"><X/></div>
							<div className="modal-body flex flex-center">
								{this.props.children}
							</div>
						</div>
					</div>
				)}
			</span>
		)
		
	}
}

export default Modal