import React, {Component} from 'react'
import {Link, NavLink} from 'react-router-dom'
import {ReactComponent as Logo} from '../../res/svg/logo.svg'
import {ReactComponent as Menu} from '../../res/svg/menu.svg'

type navbarState = {
	isBig: boolean,
	isOpen: boolean
}

class Navbar extends Component<{}, navbarState> {
	state: navbarState = {
		isBig: true,
		isOpen: false
	}
	
	handleScroll = () => {
		// If page is at the top
		const isBig: boolean = window.scrollY === 0;
		
		// If different than before
		if (isBig !== this.state.isBig) {
			this.setState({
				isBig: isBig
			})
		}
	}
	
	componentDidMount(): void {
		window.addEventListener("scroll", this.handleScroll)
	}
	
	toggleNavbar = () => {
		// Prevent page scroll
		document.body.style.overflow = this.state.isOpen ? "auto" : "hidden"
		this.setState({
			isOpen: !this.state.isOpen
		})
	}
	
	closeNavbar = () => {
		document.body.style.overflow = "auto"
		this.setState({
			isOpen: false
		})
	}
	
	render() {
		return (
			<header className={`navbar ${this.state.isBig ? 'navbar-big' : 'navbar-small'} ${this.state.isOpen ? 'navbar-open' : 'navbar-close'}`} onWheel={this.handleScroll}>
				<nav className='navbar-content full flex flex-separate flex-center-vert'>
					<span className="btn-wrapper flex flex-center-vert" onClick={this.toggleNavbar}>
						<Menu/>
					</span>
					<span className='flex'>
						<Link className="logo-container flex flex-center color" to="/">
							<Logo/>
							<span>Martin Boué</span>
						</Link>
					</span>
					<span className="navbar-list-cont">
						<ul className='flex list-link'>
							<li className='link-item'><NavLink exact={true} activeClassName="strong" to="/" onClick={this.closeNavbar}>Home</NavLink></li>
							<li className='link-item'><NavLink exact={true} activeClassName="strong" to="/about" onClick={this.closeNavbar}>About</NavLink></li>
							<li className='link-item'><NavLink exact={true} activeClassName="strong" to="/projects" onClick={this.closeNavbar}>Projects</NavLink></li>
							<li className='link-item'><NavLink exact={true} activeClassName="strong" to="/contact" onClick={this.closeNavbar}>Contact</NavLink></li>
						</ul>
					</span>
				</nav>
			</header>
		)
	}
}

export default Navbar