import React, {Component} from 'react'
import AnimatedText from "../general/AnimatedText"
import {Link} from "react-router-dom"
import {ReactComponent as Arrow} from '../../res/svg/arrow.svg'

class HomeHeader extends Component {
	render() {
		return (
			<section className="home-header flex flex-center-vert">
				<div className="home-header-body">
					<h1 className='title home-title notranslate'>
						Software engineer,<br/>developer & designer<br/>ready to <AnimatedText words={['create', 'collaborate', 'code', 'build', 'work', 'talk 😃']}/>
					</h1>
					<p className='subtitle'>Software engineer & designer in France</p>
					<div className="link-container flex flex-end">
						<Link className='strong' to='/about'>
							<span className="margin-right-1">Discover</span>
							<Arrow/>
						</Link>
					</div>
				</div>
			</section>
		)
	}
}
export default HomeHeader