import React, {Component} from 'react'

type buttonProps = {
	children: string,
	className?: string,
	shouldSubmit?: boolean,
	onClick?: (evt: React.MouseEvent) => void
}

class Button extends Component<buttonProps> {
	
	handleClick = (evt: React.MouseEvent) => {
		if(this.props.onClick) {
			this.props.onClick(evt)
		}
	}
	
	render() {
		if(this.props.shouldSubmit) {
			return (
				<input type="submit" className={`btn ${this.props.className}`} value={this.props.children} onClick={this.handleClick}/>
			)
		} else {
			return (
				<button className={`btn ${this.props.className}`} onClick={this.handleClick}>{this.props.children}</button>
			)
		}
	}
}

export default Button